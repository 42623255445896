// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

const consts = {
    CVAT_BILLING_URL: process.env.REACT_APP_CVAT_BILLING_URL ?? '/billing',
    CVAT_GITHUB_ISSUES_URL: 'https://github.com/cvat-ai/cvat/issues',
    CVAT_SUPPORT_TICKET_URL: 'https://youtrack.cvat.ai/form/447d9c98-ab4b-466e-bf9d-004f01b22f73',
};

export default consts;

// Copyright (C) 2023-2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';

import React, { useState, useEffect } from '@modules/react';
import { useSelector } from '@modules/react-redux';
import { MenuProps } from '@modules/antd/lib/menu';
import { Organization } from '@root/cvat-core-wrapper';
import { PluginEntryPoint, ComponentBuilder } from '@root/components/plugins-entrypoint';

import { CombinedState } from '@root/reducers';
import { SupportIcon, UpgradeIcon } from './icons';
import consts from './consts';

const PLUGIN_NAME = 'Subscription';

interface MenuButtonProps {
    link: string;
    text: string;
    icon: JSX.Element;
    className: string;
    key: string;
}

const MenuButtonHOC = (
    hasLimits: () => null | Promise<boolean>,
    getProps: (isPremium: boolean, currentOrganization: Organization | null) => MenuButtonProps,
) => (
    { targetProps }: { targetProps: { currentOrganization: Organization | null } },
): NonNullable<MenuProps['items']>[0] => {
    const user = useSelector((state: CombinedState) => state.auth.user);
    const organization = useSelector((state: CombinedState) => state.organizations.current);
    const [isPremium, setIsPremium] = useState(false);

    useEffect(() => {
        const val = hasLimits();
        if (val !== null) {
            val.then((_hasLimits) => {
                setIsPremium(_hasLimits);
            });
        }
    }, [user, organization]);

    const { currentOrganization } = targetProps;
    const {
        link,
        text,
        icon,
        className,
        key,
    } = getProps(isPremium, currentOrganization);

    return {
        className,
        icon,
        key,
        onClick: () => window.open(link, '_self'),
        label: text,
    };
};

const UpgradeAccountComponent: ComponentBuilder = ({
    dispatch, core, REGISTER_ACTION, REMOVE_ACTION,
}) => {
    let _hasLimits: null | Promise<boolean> = null;
    const hasLimits = () => _hasLimits;
    const globalStateDidUpdate = (state: CombinedState) => {
        if (
            _hasLimits === null &&
            state.auth.user !== null &&
            state.organizations.current !== undefined
        ) {
            // login

            // organization initialized sometime after login
            // but it is necessary to get correct limits
            // so, we are waiting while organization is ready (!== undefined)
            const org = state.organizations.current;
            const { user } = state.auth;
            _hasLimits = new Promise((resolve) => {
                core.server.request('/api/limits', {
                    method: 'GET',
                    params: {
                        ...(org ? { org_id: org.id } : { user_id: user.id }),
                    },
                }).then((response) => {
                    resolve(response.data?.count !== 0);
                });
            });
        }

        if (_hasLimits !== null && state.auth.user === null) {
            // logout
            _hasLimits = null;
        }
    };

    const upgradeButtonProps = (isPremium: boolean, currentOrganization: Organization | null): MenuButtonProps => {
        let upgradeText = isPremium ? 'Manage Solo plan' : 'Upgrade to Solo';
        let upgradeLink = `${consts.CVAT_BILLING_URL}/?type=personal`;

        if (currentOrganization) {
            upgradeText = isPremium ? 'Manage Team plan' : 'Upgrade to Team';
            upgradeLink = `${consts.CVAT_BILLING_URL}/?type=organization&orgId=${currentOrganization.id}`;
        }

        return {
            link: upgradeLink,
            text: upgradeText,
            icon: <UpgradeIcon />,
            className: 'cvat-menu-item-highlighted cvat-menu-item-upgrade',
            key: 'upgrade',
        };
    };

    const supportButtonProps = (isPremium: boolean): MenuButtonProps => {
        let supportLink = consts.CVAT_GITHUB_ISSUES_URL;

        if (isPremium) {
            supportLink = consts.CVAT_SUPPORT_TICKET_URL;
        }

        return {
            link: supportLink,
            text: 'Support',
            icon: <SupportIcon />,
            className: 'cvat-menu-item-support',
            key: 'support',
        };
    };

    const upgradeButton = MenuButtonHOC(hasLimits, upgradeButtonProps);
    const supportButton = MenuButtonHOC(hasLimits, supportButtonProps);

    dispatch({
        type: REGISTER_ACTION,
        payload: {
            path: 'header.userMenu.items',
            component: upgradeButton,
            data: {
                weight: 21,
            },
        },
    });

    dispatch({
        type: REGISTER_ACTION,
        payload: {
            path: 'header.userMenu.items',
            component: supportButton,
            data: {
                weight: 22,
            },
        },
    });

    return {
        name: PLUGIN_NAME,
        globalStateDidUpdate,
        destructor: () => {
            dispatch({
                type: REMOVE_ACTION,
                payload: {
                    path: 'header.userMenu.items',
                    component: upgradeButton,
                },
            });

            dispatch({
                type: REMOVE_ACTION,
                payload: {
                    path: 'header.userMenu.items',
                    component: supportButton,
                },
            });
        },
    };
};

function register() {
    if (Object.prototype.hasOwnProperty.call(window, 'cvatUI')) {
        (window as any as { cvatUI: { registerComponent: PluginEntryPoint } })
            .cvatUI.registerComponent(UpgradeAccountComponent);
    }
}

window.addEventListener('plugins.ready', register, { once: true });

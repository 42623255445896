// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React from '@modules/react';
import SVGUpgradeIcon from '../assets/upgrade-icon.svg';
import SVGSupportIcon from '../assets/support-icon.svg';

export const UpgradeIcon = React.memo((): JSX.Element => <SVGUpgradeIcon />);
export const SupportIcon = React.memo((): JSX.Element => <SVGSupportIcon />);
